/* Funktsioon, mille abil päritakse "Küsi pakkumist" vormis andmebaasist mudelite varustustasemed */

// Kasutaja valib rippmenüüst mudeli (nt Lancer), seejärel käivitub see funktsioon, mis pärib AJAXiga serverist
// vastava mudeli erinevad varustuspaketid ja kuvab nee järgmise rippmenüü valikutena

function populateModelPacks(testdrive) {
    var nonce = jQuery("#offerTab").data("nonce");
    // Kui päring tehakse proovisõiduvormis
    if (testdrive === true) {
        var packSelect = jQuery('#testdrivepacks');
        var post_id = jQuery('#testdrivemodels').val(); // Võetakse selle mudeli ID
    }
    else {
        // Kui päring hinnapakkumise vormis
        var packSelect = jQuery('#modelConfigurations');
        var post_id = jQuery('#models').val(); // Võetakse selle mudeli ID
        var pack_name = jQuery("#offerTab").data("packname");
    }

    jQuery.ajax({
        type : "post",
        dataType : "json",
        url : populateModelPacksAjax.ajaxurl,
        data : {action: "populate_modelpacks", post_id: post_id, testdrive: testdrive, nonce: nonce},
        success: function(response) {
            if(response.type == "success") {
                packSelect.find('option').remove(); // Rippmenüü tühjendatakse
                if (typeof response.packs !== 'undefined') {
                    jQuery.each(response.packs, function(i, value) { // Iga vastuseks saadud JSON array element lisatakse varustustasemete rippmenüüsse
                        packSelect.append(jQuery('<option>').text(value.name).attr('value', value.name).data('accessories', value.pack_accessories));
                        if (value.name===pack_name) {
                          packSelect.val(value.name);
                        }
                    });
                }
                else {
                    packSelect.append(jQuery('<option>').text("---").attr('value', "---"));
                }
                populateModelOptions();
            }
            else {
                console.log("Error in populateModelPacks");
            }
        }
    });
}

/* Funktsioon, mille abil asustatakse "Küsi pakkumist" vormis hetkel valitud mudelipaketile tellitava lisavarustuse nimekiri paremal pool */
function populateModelOptions() {
    var value = jQuery('#modelConfigurations').val();
    var optionitems = jQuery('#modelConfigurations option[value="' + value + '"]').data('accessories');
    if (typeof optionitems !== 'undefined') {
        var selectOptions = "";
        for (var category in optionitems) {
            if (typeof category !== 'undefined') {
                selectOptions+='<h4>' + optionitems[category].name + '</h4>';
                var categoryitems = optionitems[category].children;
                var categoryitemssorted = usort(categoryitems,compTermOrder);
                for (var optionelement in categoryitemssorted) {
                    var rawname = categoryitemssorted[optionelement].name;
                    var escapedname = rawname.replace(/"/g, '&quot;');
                    var description = categoryitemssorted[optionelement].desc;
                    selectOptions+='<span class="wpcf7-list-item first"><label><input type="checkbox" name="options[]" value="' + escapedname + '">&nbsp;<span class="wpcf7-list-item-label">' + rawname + '</span>'
                    if (typeof description !== 'undefined') {
                        selectOptions+='<br><i><span class="wpcf7-list-item-label">' + description + '</i></span>';
                    }
                    selectOptions+='</label></span>';

                }
            }
        }
        removeLoadingIcon();
    }
    else {
        var selectOptions = "Valitav lisavarustus puudub.";
    }
    jQuery("#selectOptions").html(selectOptions);
}

function usort(inputArr, sorter) {
  //  discuss at: http://phpjs.org/functions/usort/
  // original by: Brett Zamir (http://brett-zamir.me)
  // improved by: Brett Zamir (http://brett-zamir.me)
  //        note: This function deviates from PHP in returning a copy of the array instead
  //        note: of acting by reference and returning true; this was necessary because
  //        note: IE does not allow deleting and re-adding of properties without caching
  //        note: of property position; you can set the ini of "phpjs.strictForIn" to true to
  //        note: get the PHP behavior, but use this only if you are in an environment
  //        note: such as Firefox extensions where for-in iteration order is fixed and true
  //        note: property deletion is supported. Note that we intend to implement the PHP
  //        note: behavior by default if IE ever does allow it; only gives shallow copy since
  //        note: is by reference in PHP anyways
  //   example 1: stuff = {d: '3', a: '1', b: '11', c: '4'};
  //   example 1: stuff = usort(stuff, function (a, b) {return(a-b);});
  //   example 1: $result = stuff;
  //   returns 1: {0: '1', 1: '3', 2: '4', 3: '11'};

  var valArr = [],
    k = '',
    i = 0,
    strictForIn = false,
    populateArr = {};

  if (typeof sorter === 'string') {
    sorter = this[sorter];
  } else if (Object.prototype.toString.call(sorter) === '[object Array]') {
    sorter = this[sorter[0]][sorter[1]];
  }

  // BEGIN REDUNDANT
  this.php_js = this.php_js || {};
  this.php_js.ini = this.php_js.ini || {};
  // END REDUNDANT
  strictForIn = this.php_js.ini['phpjs.strictForIn'] && this.php_js.ini['phpjs.strictForIn'].local_value && this.php_js
    .ini['phpjs.strictForIn'].local_value !== 'off';
  populateArr = strictForIn ? inputArr : populateArr;

  for (k in inputArr) { // Get key and value arrays
    if (inputArr.hasOwnProperty(k)) {
      valArr.push(inputArr[k]);
      if (strictForIn) {
        delete inputArr[k];
      }
    }
  }
  try {
    valArr.sort(sorter);
  } catch (e) {
    return false;
  }
  for (i = 0; i < valArr.length; i++) { // Repopulate the old array
    populateArr[i] = valArr[i];
  }

  return strictForIn || populateArr;
}

function compTermOrder(a,b) {
  if (parseInt(a.term_order) < parseInt(b.term_order))
    return -1;
  if (parseInt(a.term_order) > parseInt(b.term_order))
    return 1;
  return 0;
}